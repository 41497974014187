<template>
  <div class="cpt-MMC_Gimbal_P0_Pro">
    <div v-interact class="hd">
      <div class="title">喊话器</div>
    </div>
    <div class="bd">
      <div class="form-wrap">
        <div class="form-item">
          <div class="label-box">喊话播放</div>
          <div class="input-box">
            <div class="icon-box">
              <span class="el-icon-video-play" @click="handle_play" />
              <span class="el-icon-video-pause" @click="handle_pause" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">下&nbsp;一&nbsp;曲</div>
          <div class="input-box">
            <el-select
              v-model="music"
              size="mini"
              placeholder="请选择下一曲"
              @change="handle_change_music"
            >
              <el-option :label="1" :value="1" />
              <el-option :label="2" :value="2" />
              <el-option :label="3" :value="3" />
            </el-select>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">控制速度</div>
          <div class="input-box">
            <div class="speedC">
              <div
                v-for="(item, index) in new Array(5).fill(1)"
                :key="index"
                class="speed"
                :style="control_speed == index + 1 ? 'background: #FFFFFF;color: #333333;' : ''"
                @click="control_speed = index + 1"
              >
                {{ index + 1 }}
              </div>
            </div>
            <!-- <el-select
              v-model="control_speed"
              size="mini"
              placeholder="请选择控制速度"
            >
              <el-option
                v-for="(item, index) in new Array(5).fill(1)"
                :key="index"
                :label="index + 1"
                :value="index + 1"
              ></el-option>
            </el-select> -->
          </div>
        </div>
      </div>
      <div class="rocker-wrap">
        <div class="title-box">
          <div class="title">俯仰操作</div>
        </div>
        <div ref="rocker" class="rocker">
          <div class="shangUp" @click="fangxiang('up')" />
          <div class="xaiUp" @click="fangxiang('down')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MMC_Gimbal_P0_Pro } from '../utils';
export default {
  data() {
    return {
      timer: null,
      music: null,
      control_speed: 3
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.init_rocker();
    });
  },
  methods: {
    handle_play() {
      const buffer = MMC_Gimbal_P0_Pro.gimbal_audio_play_ctl(2);
      this.commit_directive(buffer);
    },
    handle_pause() {
      const buffer = MMC_Gimbal_P0_Pro.gimbal_audio_play_ctl(0);
      this.commit_directive(buffer);
    },
    handle_change_music() {
      const buffer = MMC_Gimbal_P0_Pro.gimbal_audio_play_next_ctl();
      this.commit_directive(buffer);
    },
    commit_directive(buffer) {
      this.$emit('directive', buffer);
    },
    fangxiang(type) {
      let buffer = null;
      switch (type) {
        case 'up':
          buffer = MMC_Gimbal_P0_Pro.gimbal_pitch_ctrl(1, this.control_speed);
          this.commit_directive(buffer);
          break;
        case 'right':
          // _this.gimbal_yaw_ctrl(1);
          break;
        case 'left':
          // _this.gimbal_yaw_ctrl(-1);
          break;
        case 'down':
          buffer = MMC_Gimbal_P0_Pro.gimbal_pitch_ctrl(-1, this.control_speed);
          this.commit_directive(buffer);
      }
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);
        buffer = MMC_Gimbal_P0_Pro.gimbal_pitch_ctrl(0, this.control_speed);
        this.commit_directive(buffer);
      }, 500);
    },
    init_rocker() {
      let buffer = null;
      joystick({
        // zone: this.$refs["rocker"],
        // mode: "static",
        // position: { left: "50%", top: "50%" },
        // color: "#9bacbe",
        onstart: (type) => {
          switch (type) {
            case 'up':
              buffer = MMC_Gimbal_P0_Pro.gimbal_pitch_ctrl(1, this.control_speed);
              this.commit_directive(buffer);
              break;
            case 'right':
              // _this.gimbal_yaw_ctrl(1);
              break;
            case 'left':
              // _this.gimbal_yaw_ctrl(-1);
              break;
            case 'down':
              buffer = MMC_Gimbal_P0_Pro.gimbal_pitch_ctrl(-1, this.control_speed);
              this.commit_directive(buffer);
          }
        },
        onend: () => {
          buffer = MMC_Gimbal_P0_Pro.gimbal_pitch_ctrl(0, this.control_speed);
          this.commit_directive(buffer);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-MMC_Gimbal_P0_Pro {
  position: absolute;
  top: 0px;
  right: 0;
  /* background: center url("~@/assets/images/observe/fckernel/mount/01_bg.png")
    no-repeat; */
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35), inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  background-size: 100% 100%;
  width: 500px;
  height: 290px;
  box-sizing: border-box;
  padding: 10px 20px;
  .hd {
    .title {
      font-size: 18px;
      color: #08c2d1;
      font-weight: bold;
      line-height: 2;
    }
  }
  .bd {
    display: flex;
    justify-content: space-between;
    .form-wrap {
      flex-shrink: 0;
      .form-item {
        display: flex;
        align-items: center;
        height: 40px;
        .label-box {
          color: #cbd0eb;
          width: 75px;
        }
        .input-box {
          display: flex;
          align-items: center;
          .icon-box {
            [class^='el-icon-'] {
              font-size: 30px;
              color: #dce9ff;
            }
          }
        }
      }
    }
    .rocker-wrap {
      flex: 1;
      .title-box {
        .title {
          color: #cbd0eb;
        }
      }
      .rocker {
        margin: 10px auto 0;
        width: 154px;
        height: 154px;
        background: -0.5px 3.5px url('~@/assets/images/accident/operation.png') no-repeat;
        background-size: 100% calc(100% - 3.5px);
        position: relative;
        .shangUp {
          position: absolute;
          left: 34%;
          top: 0px;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          border: 0px solid red;
          cursor: pointer;
        }
        .xaiUp {
          position: absolute;
          left: 34%;
          bottom: 0px;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          border: 0px solid red;
          cursor: pointer;
        }
      }
    }
  }
}
::v-deep {
  .el-select {
    width: 165px;
    margin-right: 5px;
    .el-input {
      .el-input__inner {
        background: #000000;
        border: 1px solid #08c2d1;
        color: #dce9ff;
      }
    }
  }
  .el-input {
    width: 165px;
    margin-right: 5px;
    .el-input__inner {
      background: #000000;
      border: 1px solid #08c2d1;
      color: #dce9ff;
    }
  }
  .el-radio-group {
    .el-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner {
            border-color: #08c2d1;
            background: #08c2d1;
          }
        }
      }
      .el-radio__label {
        color: #cbd0eb;
      }
    }
  }
  .el-button {
    background: #2aefed;
    color: #000;
    border: none;
  }
}
::v-deep .el-slider__bar {
  background: #fff;
}
.speedC {
  width: 203px;
  height: 24px;
  display: flex;
  background: rgba(13, 34, 79, 0.65);
  border: 1px solid #43deff;
  border-radius: 12px;
  .speed {
    width: 41px;
    // background: #FFFFFF;
    color: #ffffff;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
  }
}
.iconfont {
  color: #2aefed;
}
</style>
